import {
  IonApp,
  IonLoading,
} from '@ionic/react';
import React from 'react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthContext, useAuthInit } from './auth';
import AppTabs from './AppTabs';
import LoginPage from './pages/LoginPage';
import NotFoundPage from './pages/NotFoundPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import './theme/variables.css';


const App: React.FC = () => {
  const { loading, auth } = useAuthInit();
  
  
  console.log(`It worked! You've Logged In-${auth}`);
  if (loading) {
    return <IonLoading isOpen />;
  }
  return (
    <IonApp>
      <AuthContext.Provider value={auth}>
      <IonReactRouter>
        <Switch>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route exact path="/forgotpassword">
          <ForgotPasswordPage />
        </Route>
        <Route path="/">
          <AppTabs  />
        </Route>
        <Redirect exact path="/" to="/" />
        <Route>
          <NotFoundPage />
        </Route>
        </Switch>
      </IonReactRouter>
      </AuthContext.Provider>
    </IonApp>
  );
};

export default App;
