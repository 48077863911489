import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

const firebaseConfigs = {
  apiKey: "AIzaSyD8habF_M_8FeNQ5wonCR5vTk-YqBqGkf8",
  authDomain: "blunovus-first-responders.firebaseapp.com",
  projectId: "blunovus-first-responders",
  storageBucket: "blunovus-first-responders.appspot.com",
  messagingSenderId: "740361447536",
  appId: "1:740361447536:web:6d5e95b710cd73da41acb5",
  measurementId: "G-LGH8N59LV4"
}

//FirstResponders22!

const app = firebase.initializeApp(firebaseConfigs);

const db = firebase.firestore();

export const analytics = firebase.analytics();
export const auth = app.auth();
export const firestore = app.firestore();

