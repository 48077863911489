import { flashOutline } from 'ionicons/icons';
import React from 'react';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    Label,
    Tooltip,
    ReferenceLine,
    YAxis,
    Legend,
    CartesianGrid,
} from 'recharts';
import './chart.css';


const Charts: React.FC <{janT: Number, febT: Number, marT: Number, aprT: Number, mayT: Number, junT: Number, julT: Number, augT: Number, septT: Number, octT: Number, novT: Number, decT: Number}> = props => {
    const quarters = [
        {
          name: 'Jan',
          2022:props.janT,
          

        },
        {
          name: 'Feb',
          2022:props.febT,
          

        },
        {
          name: 'Mar',
          2022:props.marT,
       
        },
        {
          name: 'Apr',
          2022:props.aprT,
          
        },
        {
            name: 'May',
            2022:props.mayT,
            
        },
        {
            name: 'Jun',
            2022:props.junT,
            
        },
        {
            name: 'Jul',
            2022:props.julT,
            
        },
        {
            name: 'Aug',
            2022:props.augT,
            
        },
        {
            name: 'Sept',
            2022:props.septT,
           
        },
        {
            name: 'Oct',
            2022:props.octT,
           
        },
        {
            name: 'Nov',
            2022:props.novT,
            
        },
        {
          name: 'Dec',
          2022:props.decT,
          
      },
        
      ];

      const getIntroOfPage = (label) => {
        if (label === "Jan") {
          return "January";
        }
        if (label === "Feb") {
          return "February";
        }
        if (label === "Mar") {
          return "March";
        }
        if (label === "Apr") {
          return "April";
        }
        if (label === "May") {
          return "May";
        }
        if (label === "Jun") {
            return "June";
        }
        if (label === "Jul") {
            return "July";
        }
        if (label === "Aug") {
            return "August";
        }
        if (label === "Sept") {
            return "September";
        }
        if (label === "Oct") {
          return "October";
      }
        if (label === "Nov") {
          return "November";
        }
        if (label === "Dec") {
          return "December";
        }
        return "";
      };

    const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
        return (
        <div className="custom-tooltip">
            <p className="intro">{getIntroOfPage(label)}</p>
            <p className="twentytwo"> {`2022: ${payload[0].value}%`}</p>
            
        </div>
        );
    }

    return null;
    };
    const formatYAxis = (tickItem: any) => {
      
      return tickItem.toString()+'%';
    };
    
      
    return (
        <ResponsiveContainer width="100%" height={300}>
            <BarChart width={200} height={100} data={quarters}>
            <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                
                <Bar dataKey="2022" fill="#3399ff"  />
                <YAxis label={{ value: 'Utilization %', angle: -90, position: 'insideLeft' }} tickFormatter={formatYAxis} /> 
                <Tooltip content={<CustomTooltip />} wrapperStyle={{padding: 10, backgroundColor: '#fff', borderRadius: '10px', border: '1px solid #8b61ff'}} cursor={{fill: '#3880ff54'}}/>
                <Legend />
                <XAxis 
           dataKey="name"
           fontFamily="sans-serif" 
    
           label={{ value: ''}}
           dy='25'
       />
      
                {/*<ReferenceLine 
                  strokeWidth={3}
                  y={4.5}
                  stroke="#8b61ff"
                  strokeDasharray="1"
                  label={<Label 
                            value="4.5% Is The National AVG EAP Utilization" 
                            fill="#17184C" 
                            fontFamily="sans-serif" 
                            fontWeight="bold"
                            fontSize="12px"
                            
                            />}
                  />*/}
            </BarChart>
        </ResponsiveContainer>
    );
};

export default Charts;