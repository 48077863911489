import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
//import { isPlatform } from "@ionic/react";



const COLORS = ["#bc41f6", "#8563f6", "#d77255", "#f4a153", "#f5e753", "#9ab2b7", "#1d6bff", "#3399ff", "#2d9496", "#3cbec1", "#f26954", "#9225ba", "#543da0"];


const PieCharter: React.FC <{data: any[]}> = props => {
  const filteredData = props.data.filter(d => d.value > 0);
  
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
        return (
        <div className="custom-tooltip">
            <p className="intro">{`${payload[0].name}`}</p>
        </div>
        );
    }
      return null;
    };


    return (
      <ResponsiveContainer height={400}>
        <PieChart>
      <Pie
        data={filteredData}
        
        outerRadius={80}
        dataKey="value"
        labelLine={false}
      >

        {props.data.map((change, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip content={CustomTooltip} wrapperStyle={{padding: '0px 15px 0px 15px', backgroundColor: '#fff', color: '#8b61ff', borderRadius: '10px', border: '2px solid #17184c'}}/>
      <Legend verticalAlign="bottom" height={170} iconSize={5} wrapperStyle={{display: "inline-block"}}/>

    </PieChart>
    </ResponsiveContainer>
    );
  
}

export default PieCharter;