export interface Entry {
    id: string;
    AllTimeUtilization: string;
    census: string;
    Name: string;
}

export class Client {
    public id: string
    public name: string
    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

}

export function toEntry(doc): Entry {
    return { id: doc.id, ...doc.data() };
}