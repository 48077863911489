import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonButton,
  IonSelect,
  IonItem,
  IonSelectOption,
  IonLabel,
  IonCol,
  IonText,
  IonLoading,
} from '@ionic/react';
import React, {useEffect, useState, useRef, useCallback } from 'react';
//import { useParams } from 'react-router';
import { firestore, auth } from '../firebase';
import { Client } from '../models';
import { useAuth } from '../auth';
import './home.css';
//import Dropdown from '../Dropdown';
//import EntryPage from './EntryPage';
import Charts from '../components/chart';
import PieCharter from '../components/pie';
import firebase from 'firebase';



const HomePage: React.FC = () => {
  const { userId } = useAuth();
  const [intrigue, setIntrigue] = useState<boolean>(false);
  const [user, setUser] = useState(userId); //long thing in firebase 'users'
  const loader = useRef<HTMLIonLoadingElement>(null);
  const [busy, setBusy] = useState<boolean>(false);
  const [message, setMessage] =  useState<string>("Logging out...");
  const [clientIds, setClientIds] = useState<any>([]);
  const [clientId, setClientId] = useState<any>('');
  const [orgCode, setOrgCode] = useState<string>(clientId);
  const [loggedInUserClient, setLoggedInUserClient] = useState<any>();
  
  
  const [clientInfo, setClientInfo] = useState<any>('');
  const [janO, setJanO] = useState<Number>();
  const [febO, setFebO] = useState<Number>();
  const [marO, setMarO] = useState<Number>();
  const [aprO, setAprO] = useState<Number>();
  const [mayO, setMayO] = useState<Number>();
  const [junO, setJunO] = useState<Number>();
  const [julO, setJulO] = useState<Number>();
  const [augO, setAugO] = useState<Number>();
  const [septO, setSeptO] = useState<Number>();
  const [octO, setOctO] = useState<Number>();
  const [novO, setNovO] = useState<Number>();
  const [decO, setDecO] = useState<Number>();
  //2022
  const [janTO, setJanTO] = useState<Number>();
  const [febTO, setFebTO] = useState<Number>();
  const [marTO, setMarTO] = useState<Number>();
  const [aprTO, setAprTO] = useState<Number>();
  const [mayTO, setMayTO] = useState<Number>();
  const [junTO, setJunTO] = useState<Number>();
  const [julTO, setJulTO] = useState<Number>();
  const [augTO, setAugTO] = useState<Number>();
  const [septTO, setSeptTO] = useState<Number>();
  const [octTO, setOctTO] = useState<Number>();
  const [novTO, setNovTO] = useState<Number>();
  const [decTO, setDecTO] = useState<Number>();

  /****** Courses Viewed #'s ************/
  const [emoCat, setEmoCat] = useState<Number>(0);
  const [wellnessCat, setWellnessCat] = useState<Number>(0);
  const [addictCat, setAddictCat] = useState<Number>(0);
  const [cultureCat, setCultureCat] = useState<Number>(0);

  const [personalStories, setPersonalStories] = useState<Number>(0);
  const [whyContactCat, setWhyContactCat] = useState<Number>(0);
  const [blogCat, setBlogCat] = useState<Number>(0);

  const [navAnx, setNavAnx] = useState<Number>();
  const [ocd, setOcd] = useState<Number>(0);
  const [anxInClass, setAnxInClass] = useState<Number>(0);
  const [crisisOne, setCrisisOne] = useState<Number>(0);
  const [crisisTwo, setCrisisTwo] = useState<Number>(0);
  const [crisisThree, setCrisisThree] = useState<Number>(0);
  const [griefNLoss, setGriefNLoss] = useState<Number>(0);
  const [recognizeAddiction, setRecognizeAddiction] = useState<Number>(0);
  const [deEsc, setDeEsc] = useState<Number>(0);
  const [understandAddiction, setUnderstandAddiction] = useState<Number>(0);
  const [addictionSupport, setAddictionSupport] = useState<Number>(0);
  const [resilMBOne, setResilMBOne] = useState<Number>(0);
  const [resilMBTwo, setResilMBTwo] = useState<Number>(0);
  const [resilMBThree, setResilMBThree] = useState<Number>(0);
  const [food, setFood] = useState<Number>(0);
  const [resilForFirstRes, setResilForFirstRes] = useState<Number>(0);
  const [promOne, setPromOne] = useState<Number>(0);
  const [promTwo, setPromTwo] = useState<Number>(0);
  const [promThree, setPromThree] = useState<Number>(0);
  const [enneagram, setEnneagram] = useState<Number>(0);
  const [mindfulnessOne, setMindfulnessOne] = useState<Number>(0);
  const [mindfulnessTwo, setMindfulnessTwo] = useState<Number>(0);
  const [mindfulnessThree, setMindfulnessThree] = useState<Number>(0);
  const [threeTipsChildren, setThreeTipsChildren] = useState<Number>(0);
  const [healthcare, setHealthcare] = useState<Number>(0);
  const [toughConvo, setToughConvo] = useState<Number>(0);
  const [childrenEmotions, setChildrenEmotions] = useState<Number>(0);
  const [retireVirus, setRetireVirus] = useState<Number>(0);
  const [womenRetire, setWomenRetire] = useState<Number>(0);
  const [emoElas, setEmoElas] = useState<Number>(0);
  const [brokenInad, setBrokenInad] = useState<Number>(0);
  const [mbrOne, setMBROne] = useState<Number>(0);
  const [mbrTwo, setMBRTwo] = useState<Number>(0);
  const [mbrThree, setMBRThree] = useState<Number>(0);
  const [innateListen, setInnateListen] = useState<Number>(0);
  const [reThinkHandbook, setReThinkHandbook] = useState<Number>(0);

  /****** LECTICON COMPANY  ******/
  const [totalEmp, setTotalEmp ] = useState<Number>();
  const [totalApps, setTotalApps ] = useState<Number>();
  const [allJan, setAllJan] = useState<Number>();
  const [allFeb, setAllFeb] = useState<Number>();
  const [allMar, setAllMar] = useState<Number>();
  const [allApr, setAllApr] = useState<Number>();
  const [allMay, setAllMay] = useState<Number>();
  const [allCourses, setAllCourses] = useState<Number>();
  const [allEmo, setAllEmo] = useState<Number>();
  const [allWellness, setAllWellness] = useState<Number>();
  const [allFirstRespond, setAllFirstRespond] = useState<Number>();
  const [allMentor, setAllMentor] = useState<Number>();
  const [allHope, setAllHope] = useState<Number>();
  const [allNorm, setAllNorm] = useState<Number>();

  /****** Current Company *******/
  const [hn, setHN] = useState<Number>();
  const [abuse, setAbuse] = useState<Number>();
  const [anger, setAnger] = useState<Number>();
  const [anxiety, setAnxiety] = useState<Number>();
  const [coaching, setCoaching] = useState<Number>();
  const [depression, setDepression] = useState<Number>();
  const [disorder, setDisorder] = useState<Number>();
  const [griefLoss, setGriefLoss] = useState<Number>();
  const [followUp, setFollowUp] = useState<Number>();
  const [HRRelated, setHRRelated] = useState<Number>();
  const [insomnia, setInsomnia] = useState<Number>();
  const [pornography, setPornography] = useState<Number>();
  const [relationships, setRelationships] = useState<Number>();
  const [selfHarm, setSelfHarm] = useState<Number>();
  const [stress, setStress] = useState<Number>();
  const [substance, setSubstance] = useState<Number>();
  const [suicide, setSuicide] = useState<Number>();
  const [trauma, setTrauma] = useState<Number>();
  const [other, setOther] = useState<Number>();
  const [employees, setEmployees] = useState<Number>();
  const [lovedOnes, setLovedOnes] = useState<Number>();
  const [others, setOthers] = useState<Number>();
  const [careCoaching, setCareCoaching] = useState<Number>();
  const [outsideTherapy, setOutsideTherapy] = useState<Number>();
  const [outsidePsychiatry, setOutsidePsychiatry] = useState<Number>();
  const [outsideTreatment, setOutsideTreatment] = useState<Number>();
  const [resolved, setResolved] = useState<Number>();

  
  /****** All Companies *********/
  const [abuseAll, setAbuseAll] = useState<Number>();
  const [angerAll, setAngerAll] = useState<Number>();
  const [anxietyAll, setAnxietyAll] = useState<Number>();
  const [coachingAll, setCoachingAll] = useState<Number>();
  const [depressionAll, setDepressionAll] = useState<Number>();
  const [disorderAll, setDisorderAll] = useState<Number>();
  const [griefLossAll, setGriefLossAll] = useState<Number>();
  const [followUpAll, setFollowUpAll] = useState<Number>();
  const [HRRelatedAll, setHRRelatedAll] = useState<Number>();
  const [insomniaAll, setInsomniaAll] = useState<Number>();
  const [pornographyAll, setPornographyAll] = useState<Number>();
  const [relationshipsAll, setRelationshipsAll] = useState<Number>();
  const [selfHarmAll, setSelfHarmAll] = useState<Number>();
  const [stressAll, setStressAll] = useState<Number>();
  const [substanceAll, setSubstanceAll] = useState<Number>();
  const [suicideAll, setSuicideAll] = useState<Number>();
  const [traumaAll, setTraumaAll] = useState<Number>();
  const [otherAll, setOtherAll] = useState<Number>();
  const [employeesAll, setEmployeesAll] = useState<Number>();
  const [lovedOnesAll, setLovedOnesAll] = useState<Number>();
  const [othersAll, setOthersAll] = useState<Number>();
  const [careCoachingAll, setCareCoachingAll] = useState<Number>();
  const [outsideTherapyAll, setoutsideTherapyAll] = useState<Number>();
  const [outsidePsychiatryAll, setOutsidePsychiatryAll] = useState<Number>();
  const [outsideTreatmentAll, setOutsideTreatmentAll] = useState<Number>();
  const [resolvedAll, setResolvedAll] = useState<Number>();

  

  const GetClientInfo = useCallback(() => {
    if(clientInfo) {
    setMessage("Loading client info...");
    setBusy(true);


    /*******CURRENT COMPANY*******/
    const hopenorm = clientInfo?.HopeAndNorm;
    if (hopenorm !== null) {
      setHN(hopenorm);
    }
    const abuse = clientInfo?.OrgAbuse;
    if (abuse !== null) {
      setAbuse(abuse);
    }
    const anger = clientInfo?.OrgAnger;
    if (anger !== null) {
      setAnger(anger);
    }
    const anxiety = clientInfo?.OrgAnxiety;
    if (anxiety !== null) {
      setAnxiety(anxiety);
    }
    const coaching = clientInfo?.OrgCoaching;
    if (coaching !== null) {
      setCoaching(coaching);
    }
    const depression = clientInfo?.OrgDepression;
    if (depression !== null) {
      setDepression(depression);
    }
    const disorder = clientInfo?.OrgDisorder;
    if (disorder !== null) {
      setDisorder(disorder);
    }
    const followUp = clientInfo?.OrgFollowUp;
    if (followUp !== null) {
      setFollowUp(followUp);
    }
    const grief = clientInfo?.OrgGrieforLoss;
    if (grief !== null) {
      setGriefLoss(grief);
    }
    const HRRelated = clientInfo?.OrgHRRelated;
    if (HRRelated !== null) {
      setHRRelated(HRRelated);
    }
    const insomnia = clientInfo?.OrgInsomnia;
    if (insomnia !== null) {
      setInsomnia(insomnia);
    }
    const pornography = clientInfo?.OrgPornography;
    if (pornography !== null) {
      setPornography(pornography);
    }
    const relationships = clientInfo?.OrgRelationships;
    if (relationships !== null) {
      setRelationships(relationships);
    }
    const selfHarm = clientInfo?.OrgSelfHarm;
    if (selfHarm !== null) {
      setSelfHarm(selfHarm);
    }
    const stress = clientInfo?.OrgStress;
    if (stress !== null) {
      setStress(stress);
    }
    const substance = clientInfo?.OrgSubstanceMisuse;
    if (substance !== null) {
      setSubstance(substance);
    }
    const suicide = clientInfo?.OrgSuicideIdeation;
    if (suicide !== null) {
      setSuicide(suicide);
    }
    const trauma = clientInfo?.OrgTrauma;
    if (trauma !== null) {
      setTrauma(trauma);
    }
    const other = clientInfo?.OrgOther;
    if (other !== null) {
      setOther(other);
    }
    const Employees = clientInfo?.OrgEmployee;
    if (Employees !== null) {
      setEmployees(Employees);
    }
    const LovedOnes = clientInfo?.OrgLovedOnes;
    if (LovedOnes !== null) {
      setLovedOnes(LovedOnes);
    }
    const OtherPeeps = clientInfo?.OrgOthers;
    if (OtherPeeps !== null) {
      setOthers(OtherPeeps);
    }
    const CareCoaching = clientInfo.OrgCareCoaching;
    if (CareCoaching !== null) {
      setCareCoaching(CareCoaching);
    }
    const outsideTherapy = clientInfo.OrgOutsideTherapy;
    if (outsideTherapy !== null) {
      setOutsideTherapy(outsideTherapy);
    }
    const outsidePsych = clientInfo?.OrgOutsidePsychiatry;
    if (outsidePsych !== null) {
      setOutsidePsychiatry(outsidePsych);
    }
    const outsideTreat = clientInfo?.OrgOutsideTreatment;
    if (outsideTreat !== null) {
      setOutsideTreatment(outsideTreat);
    }
    const resolved = clientInfo?.OrgResolved;
    if (resolved !== null) {
      setResolved(resolved);
    }

    /****** Courses Stats Assignment  *****/
    const personalstory = clientInfo?.PersonalStories;
    if (personalstory !== null ) {
      setPersonalStories(personalstory);
    }
    const emotstat = clientInfo?.EmotionalTot;
    if (emotstat !== null ) {
      setEmoCat(emotstat);
    }
    const wellnessstat = clientInfo?.WellnessTot;
    if (wellnessstat !== null ) {
      setWellnessCat(wellnessstat);
    }
    const cultstat = clientInfo?.FirstRespondTot;
    if (cultstat !== null ) {
      setCultureCat(cultstat);
    }
    const addictstat = clientInfo?.AddictionTot;
    if (addictstat !== null ) {
      setAddictCat(addictstat);
    }
    const blogstat = clientInfo?.Premium;
    if (blogstat !== null ) {
      setBlogCat(blogstat);
    }
    const whystat = clientInfo?.WhyContact;
    if (whystat !== null ) {
      setWhyContactCat(whystat);
    }

    const navstat = clientInfo?.navAnx;
    if (navstat !== null ) {
      setNavAnx(navstat);
    }
    const ocdstat = clientInfo?.ocd;
    if (ocdstat !== null ) {
      setOcd(ocdstat);
    }
    const anxclassstat = clientInfo?.anxInClass;
    if (anxclassstat !== null ) {
      setAnxInClass(anxclassstat);
    }
    const crisisOStat = clientInfo?.crisisOne;
    if (crisisOStat !== null ) {
      setCrisisOne(crisisOStat);
    }
    const crisisTStat = clientInfo?.crisisTwo;
    if (crisisTStat !== null ) {
      setCrisisTwo(crisisTStat);
    }
    const crisisThStat = clientInfo?.crisisThree;
    if (crisisThStat !== null ) {
      setCrisisThree(crisisThStat);
    }
    const griefstat = clientInfo?.griefNLoss;
    if (griefstat !== null ) {
      setGriefNLoss(griefstat);
    }
    const deescstat = clientInfo?.deEsc;
    if (deescstat !== null ) {
      setDeEsc(deescstat);
    }
    const recogstat = clientInfo?.recognizeAddiction;
    if (recogstat !== null ) {
      setRecognizeAddiction(recogstat);
    }
    const understanaddstat = clientInfo?.understandAddiction;
    if (understanaddstat !== null ) {
      setUnderstandAddiction(understanaddstat);
    }
    const addictsupportstat = clientInfo?.addictionSupport;
    if (addictsupportstat !== null ) {
      setAddictionSupport(addictsupportstat);
    }
    const resilmbonestat = clientInfo?.resilMBOne;
    if (resilmbonestat !== null ) {
      setResilMBOne(resilmbonestat);
    }
    const resilmbTwoStat = clientInfo?.resilMBTwo;
    if (resilmbTwoStat !== null ) {
      setResilMBTwo(resilmbTwoStat);
    }
    const resilmbThreeStat = clientInfo?.resilMBThree;
    if (resilmbThreeStat !== null ) {
      setResilMBThree(resilmbThreeStat);
    }
    const foodstat = clientInfo?.food;
    if (foodstat !== null ) {
      setFood(foodstat);
    }
    const resilforfirststat = clientInfo?.resilForFirstRes;
    if (resilforfirststat !== null ) {
      setResilForFirstRes(resilforfirststat);
    }
    const promOneStat = clientInfo?.PromOne;
    if (promOneStat !== null ) {
      setPromOne(promOneStat);
    }
    const promTwoStat = clientInfo?.PromTwo;
    if (promTwoStat !== null ) {
      setPromTwo(promTwoStat);
    }
    const promThreeStat = clientInfo?.PromThree;
    if (promThreeStat !== null ) {
      setPromThree(promThreeStat);
    }
    const enneagramStat = clientInfo?.Enneagram;
    if (enneagramStat !== null ) {
      setEnneagram(enneagramStat);
    }
    const mindfulOneStat = clientInfo?.mindfulnessOne;
    if (mindfulOneStat !== null ) {
      setMindfulnessOne(mindfulOneStat);
    }
    const mindfulTwoStat = clientInfo?.mindfulnessTwo;
    if (mindfulTwoStat !== null ) {
      setMindfulnessTwo(mindfulTwoStat);
    }
    const mindfulThreeStat = clientInfo?.mindfulnessThree;
    if (mindfulThreeStat !== null ) {
      setMindfulnessThree(mindfulThreeStat);
    }
    const threeTipsStat = clientInfo?.threeTipsChildren;
    if (threeTipsStat !== null ) {
      setThreeTipsChildren(threeTipsStat);
    }
    const healthcareStat = clientInfo?.healthcare;
    if (healthcareStat !== null ) {
      setHealthcare(healthcareStat);
    }
    const toughConvoStat = clientInfo?.toughConvo;
    if (toughConvoStat !== null ) {
      setToughConvo(toughConvoStat);
    }
    const childrenEmoStat = clientInfo?.childrenEmotions;
    if (childrenEmoStat !== null ) {
      setChildrenEmotions(childrenEmoStat);
    }
    const retireVirusStat = clientInfo?.retireVirus;
    if (retireVirusStat !== null ) {
      setRetireVirus(retireVirusStat);
    }
    const womenRetireStat = clientInfo?.womenRetire;
    if (womenRetireStat !== null ) {
      setWomenRetire(womenRetireStat);
    }
    const emoElasStat = clientInfo?.emoElas;
    if (emoElasStat !== null ) {
      setEmoElas(emoElasStat);
    }
    const brokenStat = clientInfo?.brokenInad;
    if (brokenStat !== null ) {
      setBrokenInad(brokenStat);
    }
    const mbrOneStat = clientInfo?.mbrOne;
    if (mbrOneStat !== null ) {
      setMBROne(mbrOneStat);
    }
    const mbrTwoStat = clientInfo?.mbrTwo;
    if (mbrTwoStat !== null ) {
      setMBRTwo(mbrTwoStat);
    }
    const mbrThreeStat = clientInfo?.mbrThree;
    if (mbrThreeStat !== null ) {
      setMBRThree(mbrThreeStat);
    }
    const listenStat = clientInfo?.InnateListening;
    if (listenStat !== null ) {
      setInnateListen(listenStat);
    }
    const handbookStat = clientInfo?.FirstResponders;
    if (handbookStat !== null ) {
      setReThinkHandbook(handbookStat);
    }
    
    


    /*******ALL COMPANIES*********/
    const abuseA = clientInfo?.ACReasonsAbuse;
    if (abuseA !== null) {
      setAbuseAll(abuseA);
    }
    const angerA = clientInfo?.ACReasonsAnger;
    if (angerA !== null) {
      setAngerAll(angerA);
    }
    const anxietyA = clientInfo?.ACReasonsAnxiety;
    if (anxietyA !== null) {
      setAnxietyAll(anxietyA);
    }
    const coachingA = clientInfo?.ACReasonsCoaching;
    if (coachingA !== null) {
      setCoachingAll(coachingA);
    }
    const depressionA = clientInfo?.ACReasonsDepression;
    if (depressionA !== null) {
      setDepressionAll(depressionA);
    }
    const disorderA = clientInfo?.ACReasonsDisorder;
    if (disorderA !== null) {
      setDisorderAll(disorderA);
    }
    const followUpA = clientInfo?.ACReasonsFollowUp;
    if (followUpA !== null) {
      setFollowUpAll(followUpA);
    }
    const griefA = clientInfo?.ACReasonsGriefOrLoss;
    if (griefA !== null) {
      setGriefLossAll(griefA);
    }
    const HRRelatedA = clientInfo?.ACReasonsHRRelated;
    if (HRRelatedA !== null) {
      setHRRelatedAll(HRRelatedA);
    }
    const pornographyA = clientInfo?.ACReasonsPornography;
    if (pornographyA !== null) {
      setPornographyAll(pornographyA);
    }
    const insomniaA = clientInfo?.ACReasonsInsomnia;
    if (insomniaA !== null) {
      setInsomniaAll(insomniaA);
    }
    const relationshipsA = clientInfo?.ACReasonsRelationships;
    if (relationshipsA !== null) {
      setRelationshipsAll(relationshipsA);
    }
    const selfHarmA = clientInfo?.ACReasonsSelfHarm;
    if (selfHarmA !== null) {
      setSelfHarmAll(selfHarmA);
    }
    const stressA = clientInfo?.ACReasonsStress;
    if (stressA !== null) {
      setStressAll(stressA);
    }
    const substanceA = clientInfo?.ACReasonsSubstanceMisuse;
    if (substanceA !== null) {
      setSubstanceAll(substanceA);
    }
    const suicideA = clientInfo?.ACReasonsSuicide;
    if (suicideA !== null) {
      setSuicideAll(suicideA);
    }
    const traumaA = clientInfo?.ACReasonsTrauma;
    if (traumaA !== null) {
      setTraumaAll(traumaA);
    }
    const otherA = clientInfo?.ACReasonsOther;
    if (otherA !== null) {
      setOtherAll(otherA);
    }
    const allEmployees = clientInfo?.ACTotEmployee;
    if (allEmployees !== null) {
      setEmployeesAll(allEmployees);
    }
    const allLovedOnes = clientInfo?.ACTotLovedOnes;
    if (allLovedOnes !== null) {
      setLovedOnesAll(allLovedOnes);
    }
    const OtherPeepsAll = clientInfo?.ACTotOther;
    if (OtherPeepsAll !== null) {
      setOthersAll(OtherPeepsAll);
    }
    const allCareCoaching = clientInfo.ACOCareCoaching;
    if (allCareCoaching !== null) {
      setCareCoachingAll(allCareCoaching);
    }
    const outsideTherapyA = clientInfo.ACOOutsideTherapy;
    if (outsideTherapyA !== null) {
      setoutsideTherapyAll(outsideTherapyA);
    }
    const outsidePsychAll = clientInfo?.ACOOutsidePsychiatry;
    if (outsidePsychAll !== null) {
      setOutsidePsychiatryAll(outsidePsychAll);
    }
    const outsideTreatAll = clientInfo?.ACOOutsideTreatment;
    if (outsideTreatAll !== null) {
      setOutsideTreatmentAll(outsideTreatAll);
    }
    const resolvedAll = clientInfo?.ACOResolved;
    if (resolvedAll !== null) {
      setResolvedAll(resolvedAll);
    }
    
    setBusy(false)};
    },[clientInfo]);
  
    useCallback(() => {
      if(!intrigue) {
        setUser(userId);
      }
    }, [intrigue, userId])


  const fetchClientIds = async () => {
    const _clientIdsDoc = await firestore.collection('clients').get();
    const _clientIds = []
    _clientIdsDoc.forEach(x => _clientIds.push(new Client(x.id, x.data().Name)))
    setClientIds(_clientIds)
  }

  
  const getUserInfo = async () => {
    const _userDoc = await firestore.collection('users').where(firebase.firestore.FieldPath.documentId(), '==', userId).get();
    let _loggedInUserClientId
    _userDoc.docs.forEach(x => _loggedInUserClientId = x.data().clientId)
    const _clientIdsDoc = await firestore.collection('clients').where(firebase.firestore.FieldPath.documentId(), '==', _loggedInUserClientId).get();
    let _loggedInUserClient
    _clientIdsDoc.forEach(x => _loggedInUserClient = x.data())
    setLoggedInUserClient(_loggedInUserClient)
    setIntrigue(_loggedInUserClient?.Admin)
    setClientId(_loggedInUserClientId)
    console.log(_loggedInUserClientId);
  }
  const fetchClientInfo = async (_clientId) => {
    
    const _clientDoc = await firestore.collection('clients').where(firebase.firestore.FieldPath.documentId(), '==', _clientId).get();

    const _clientDocT = await firestore.collection("clients").doc(_clientId).collection("2022-CCI").where(firebase.firestore.FieldPath.documentId(), '==', "CareCenterInteractions").get();
    let _clientInfo
    let _clientInfoT
    
    _clientDoc.forEach(x => _clientInfo = x.data())   
    
    
  
    _clientDocT.forEach(x => _clientInfoT = x.data())
    //console.log(`CI: ${_clientDocT}`); 
    //console.log(_clientInfoT);

    const janO = (_clientInfo.CCIJan);
    const febO = (_clientInfo.CCIFeb);
    const marO = (_clientInfo.CCIMar);
    const aprO = (_clientInfo.CCIApr);
    const mayO = (_clientInfo.CCIMay);
    const junO = (_clientInfo.CCIJun);
    const julO = (_clientInfo.CCIJul);
    const augO = (_clientInfo.CCIAug);
    const septO = (_clientInfo.CCISept);
    const octO = (_clientInfo.CCIOct);
    const novO = (_clientInfo.CCINov);
    const decO = (_clientInfo.CCIDec);
    
    const janTO = 0;//(_clientInfoT.CCIJanT);
    const febTO = 0;//(_clientInfoT.CCIFebT);
    const marTO = 0;//(_clientInfoT.CCIMarT);
    const aprTO = 0;//(_clientInfoT.CCIAprT);
    const mayTO = 0;//(_clientInfoT.CCIMayT);
    const junTO = 0;//(_clientInfoT.CCIJunT);
    const julTO = 0;//(_clientInfoT.CCIJulT);
    const augTO = 0;//(_clientInfoT.CCIAugT);
    const septTO = 0;//(_clientInfoT.CCISeptT);
    const octTO = 0;//(_clientInfoT.CCIOctT);
    const novTO = 0;//(_clientInfoT.CCINovT);
    const decTO = 0;//(_clientInfoT.CCIDecT);
    setJanTO(janO);
    setFebTO(febO);
    setMarTO(marO);
    setAprTO(aprO);
    setMayTO(mayO);
    setJunTO(junO);
    setJulTO(julO);
    setAugTO(augO);
    setSeptTO(septO);
    setOctTO(octO);
    setNovTO(novO);
    setDecTO(decO);
   

    setJanO(janTO);
    setFebO(febTO);
    setMarO(marTO);
    setAprO(aprTO);
    setMayO(mayTO);
    setJunO(junTO);
    setJulO(julTO);
    setAugO(augTO);
    setSeptO(septTO);
    setOctO(octTO);
    setNovO(novTO);
    setDecO(decTO);
    setClientInfo(_clientInfo)
  }
  

  useEffect(() => {
    if (!loggedInUserClient) getUserInfo(); // Get logged in user stuff
     GetClientInfo(); // Get client stuff
    if (intrigue && clientIds.length === 0) {
      fetchClientIds();
    }

    if (clientId) fetchClientInfo(clientId)
    
  }, [intrigue, clientId, GetClientInfo]);

  return (
    
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle >Blunovus First Responders Engagement</IonTitle>
          { intrigue &&
          <IonItem className="orgSelect">
        
          <IonLabel>Select Organization</IonLabel>
          
          <IonSelect placeholder={clientInfo?.Name} value={orgCode} okText="Choose" cancelText="Dismiss" onIonChange={e => setClientId(e.detail.value)}>
            
          {clientIds.map((client) =>
            <IonSelectOption key={client.id} value={client.id}>{client?.name}</IonSelectOption>)}
          </IonSelect>
        </IonItem>
          }
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
      <IonLoading message={message} duration={1} isOpen={busy} ref={loader} />
        <IonGrid fixed={false}>
        <IonRow>
          <IonCol className="blue ion-padding">
            Emotional Prosperity Training
            <IonRow className="centered">
              <IonCol className="num-title">
              Employees Trained
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="big-num">
              {clientInfo?.EmployeesTrained}
              </IonCol>
            </IonRow>
            <IonRow>
            <IonCol className="num-title">
              Managers Trained
              </IonCol>
            </IonRow>
            <IonRow>
            <IonCol className="big-num">
              {clientInfo?.ManagersTrained}
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol className="three ion-padding">
            <IonRow>
              <IonCol className="chart-title ion-padding">
              Annualized Care Center (EXAMPLE DATA)
              </IonCol>
            </IonRow>
            <Charts 
              janT = {janTO}
              febT = {febTO}
              marT = {marTO}
              aprT = {aprTO}
              mayT = {mayTO}
              junT = {junTO}
              julT = {julTO}
              augT = {augTO}
              septT = {septTO}
              octT = {octTO}
              novT = {novTO}
              decT = {decTO}

        
            />
          </IonCol>
          
        </IonRow>
        <IonRow className="move-down">
          <IonCol className="ion-padding title-item">
            YTD Care Center
            <IonRow>
            <IonCol className="info-num">
          {/*((Math.round(clientInfo?.CareCenterUtilization)*100)/100)*/}N/A
          </IonCol>
          </IonRow> 
          </IonCol>
          <IonCol className="ion-padding title-item">
            Hope & Norm
            <IonRow>
          <IonCol className="info-num">
          {/*hn*/}N/A
          </IonCol>
          </IonRow>
          </IonCol>
          <IonCol className="ion-padding title-item">
            Courses Viewed
            <IonRow>
          <IonCol className="info-num">
          {clientInfo?.CoursesViewed}
          </IonCol>
          </IonRow>
          </IonCol>
          
          <IonCol className="ion-padding title-item">
            {intrigue && "Total App Downloads"}
            {!intrigue && "App Downloads"}
            <IonRow>
          <IonCol className=" info-num">
          {clientInfo?.App}
          </IonCol>
          </IonRow>
          </IonCol>
        </IonRow>
    </IonGrid>
    <IonTitle className="large-header">Resource Analytics</IonTitle>
    <IonGrid fixed={false}>
        <IonRow>
          <IonCol className="col-change"><IonRow className="underline-me">
          <IonCol className="pie-head">Course Categories</IonCol></IonRow>
          <IonRow className="underline-me">
          <IonCol className="pie-inn">Emotional Health</IonCol><IonCol className="pie-inn">{emoCat}</IonCol>
        </IonRow>
        <IonRow className="underline-me">
          <IonCol className="pie-inn">Wellness</IonCol><IonCol className="pie-inn">{wellnessCat}</IonCol>
        </IonRow>
        <IonRow className="underline-me">
          <IonCol className="pie-inn">Addiction</IonCol><IonCol className="pie-inn">{addictCat}</IonCol>
        </IonRow>
        <IonRow className="underline-me">
          <IonCol className="pie-inn">First Responders</IonCol><IonCol className="pie-inn">{cultureCat}</IonCol>
        </IonRow>
        <IonCol className="col-change"><IonRow className="underline-me">
          <IonCol className="pie-head">Page Visits</IonCol></IonRow>
          { personalStories !== 0 && 
          <IonRow className="underline-me">
          <IonCol className="pie-inn">Personal Stories of Hope</IonCol><IonCol className="pie-inn">{personalStories}</IonCol>
        </IonRow>
        }
        { whyContactCat !== 0 && 
        <IonRow className="underline-me">
          <IonCol className="pie-inn">Why Contact the Care Center?</IonCol><IonCol className="pie-inn">{whyContactCat}</IonCol>
        </IonRow>
        }
        { blogCat !== 0 && 
        <IonRow className="underline-me">
          <IonCol className="pie-inn">Mentors</IonCol><IonCol className="pie-inn">{blogCat}</IonCol>
        </IonRow>
        }
        <IonRow className="underline-me">
          <IonCol className="pie-inn"></IonCol><IonCol className="pie-inn"></IonCol>
        </IonRow>
          </IonCol>
          </IonCol>
          
          <IonCol><IonRow className="underline-me-backwards">
          <IonCol className="pie-head course-titles">Courses Viewed - Title</IonCol></IonRow>
          { navAnx !== 0 && 
          <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Navigating Anxiety</IonCol><IonCol className="pie-inn">{navAnx}</IonCol>
        </IonRow>
        }
        { ocd !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Managing OCD</IonCol><IonCol className="pie-inn">{ocd}</IonCol>
        </IonRow>
        }
        { anxInClass !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Anxiety In the Classroom</IonCol><IonCol className="pie-inn">{anxInClass}</IonCol>
        </IonRow>
        }
        { griefNLoss !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Grief & Loss</IonCol><IonCol className="pie-inn">{griefNLoss}</IonCol>
        </IonRow>
        }
        { crisisOne !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Crisis Management 1</IonCol><IonCol className="pie-inn">{crisisOne}</IonCol>
        </IonRow>
        }
        { crisisTwo !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Crisis Management 2</IonCol><IonCol className="pie-inn">{crisisTwo}</IonCol>
        </IonRow>
        }
        { crisisThree !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Crisis Management 3</IonCol><IonCol className="pie-inn">{crisisThree}</IonCol>
        </IonRow>
        }
        { deEsc !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">De-Escalation During Emotional Crises</IonCol><IonCol className="pie-inn">{deEsc}</IonCol>
        </IonRow>
        }
        { recognizeAddiction !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Recognizing Addiction</IonCol><IonCol className="pie-inn">{recognizeAddiction}</IonCol>
        </IonRow>
        }
        { understandAddiction !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Understanding Addiction</IonCol><IonCol className="pie-inn">{understandAddiction}</IonCol>
        </IonRow>
        }
        { addictionSupport !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Addiction Support</IonCol><IonCol className="pie-inn">{addictionSupport}</IonCol>
        </IonRow>
        }
        { resilMBOne !== 0 && 
        <IonRow className="underline-me-backwards"> 
          <IonCol className="pie-inn">Resilience: Mind & Body Resilience 1</IonCol><IonCol className="pie-inn">{resilMBOne}</IonCol>
        </IonRow>
        }
        { resilMBTwo !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Resilience: Mind & Body Resilience 2</IonCol><IonCol className="pie-inn">{resilMBTwo}</IonCol>
        </IonRow>
        }
        { resilMBThree !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Resilience: Mind & Body Resilience 3</IonCol><IonCol className="pie-inn">{resilMBThree}</IonCol>
        </IonRow>
        }
        { food !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Your Relationship With Food</IonCol><IonCol className="pie-inn">{food}</IonCol>
        </IonRow>
        }
        { resilForFirstRes !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Resilience For First Responders</IonCol><IonCol className="pie-inn">{resilForFirstRes}</IonCol>
        </IonRow>
        }
        { promOne !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">The Promise 1</IonCol><IonCol className="pie-inn">{promOne}</IonCol>
        </IonRow>
        }
        { promTwo !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">The Promise 2</IonCol><IonCol className="pie-inn">{promTwo}</IonCol>
        </IonRow>
        }
        { promThree !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">The Promise 3</IonCol><IonCol className="pie-inn">{promThree}</IonCol>
        </IonRow>
        }
        { enneagram !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Introduction to the Enneagram</IonCol><IonCol className="pie-inn">{enneagram}</IonCol>
        </IonRow>
        }
        { mindfulnessOne !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Mindfulness Introduction</IonCol><IonCol className="pie-inn">{mindfulnessOne}</IonCol>
        </IonRow>
        }
        { mindfulnessTwo !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Mindfulness Tools</IonCol><IonCol className="pie-inn">{mindfulnessTwo}</IonCol>
        </IonRow>
        }
        { mindfulnessThree !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Mindfulness Demonstration</IonCol><IonCol className="pie-inn">{mindfulnessThree}</IonCol>
        </IonRow>
        }
        { threeTipsChildren !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Tips For Communicating Tough Subjects w/ Children</IonCol><IonCol className="pie-inn">{threeTipsChildren}</IonCol>
        </IonRow>
        }
        { healthcare !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Help Your Healthcare Provider Help You</IonCol><IonCol className="pie-inn">{healthcare}</IonCol>
        </IonRow>
        }
        { toughConvo !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">How To Have Tough Conversations</IonCol><IonCol className="pie-inn">{toughConvo}</IonCol>
        </IonRow>
        }
        { childrenEmotions !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Talk To Children About Challenging Emotions</IonCol><IonCol className="pie-inn">{childrenEmotions}</IonCol>
        </IonRow>
        }
        { retireVirus !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Does Your Retirement Plan Have a Virus</IonCol><IonCol className="pie-inn">{retireVirus}</IonCol>
        </IonRow>
        }
        { womenRetire !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Empowering Women For Today's Retirement</IonCol><IonCol className="pie-inn">{womenRetire}</IonCol>
        </IonRow>
        }
        { emoElas !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Emotional Elasticity</IonCol><IonCol className="pie-inn">{emoElas}</IonCol>
        </IonRow>
        }
        { brokenInad !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">You're Not Broken or Inadequate</IonCol><IonCol className="pie-inn">{brokenInad}</IonCol>
        </IonRow>
        }
        { mbrOne !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Resilience: Mind & Body 1</IonCol><IonCol className="pie-inn">{mbrOne}</IonCol>
        </IonRow>
        }
        { mbrTwo !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Resilience: Mind & Body 2</IonCol><IonCol className="pie-inn">{mbrTwo}</IonCol>
        </IonRow>
        }
        { mbrThree !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Resilience: Mind & Body 3</IonCol><IonCol className="pie-inn">{mbrThree}</IonCol>
        </IonRow>
        }
        { innateListen !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Innate Listening</IonCol><IonCol className="pie-inn">{innateListen}</IonCol>
        </IonRow>
        }
        { reThinkHandbook !== 0 && 
        <IonRow className="underline-me-backwards">
          <IonCol className="pie-inn">Re-Thinking the Employee Handbook</IonCol><IonCol className="pie-inn">{reThinkHandbook}</IonCol>
        </IonRow>}
          </IonCol>
        </IonRow>
        <IonRow>
          
          
          
        </IonRow>
    </IonGrid> 
     { intrigue &&
    <IonTitle className="large-header">Care Center Insights</IonTitle>}
    { intrigue &&  
    <IonGrid fixed={false}>
        <IonRow>
          <IonCol className="pie-size">
          <IonText className="pie-head">Reason for Reaching In</IonText>
            <IonRow className="cusion-row">
              <IonCol>
              <IonText  className="pie-title">Your Company</IonText>
                <PieCharter
                
                data = {[
                  {
                    name: `Abuse: ${abuse}%`,
                    value: abuse
                  },
                  { 
                    name: `Anger: ${anger}%`, 
                    value: anger
                  },
                  { 
                    name: `Anxiety: ${anxiety}%`, 
                    value: anxiety
                  },
                  { 
                    name: `Coaching: ${coaching}%`, 
                    value: coaching
                  },
                  { 
                    name: `Depression: ${depression}%`, 
                    value: depression
                  },
                  {
                    name: `Mental Disorder: ${disorder}%`,
                    value: disorder
                  },
                  {
                    name: `Follow Up: ${followUp}%`,
                    value: followUp
                  },
                  { 
                    name: `Grief Or Loss: ${griefLoss}%`, 
                    value: griefLoss
                  },
                  {
                    name: `Insomnia: ${insomnia}%`,
                    value: insomnia
                  },
                  {
                    name: `Pornography: ${pornography}%`,
                    value: pornography
                  },
                  {
                    name: `Self-Harm: ${selfHarm}%`,
                    value: selfHarm
                  },
                  { 
                    name:  `Stress: ${stress}%`, 
                    value: stress
                  },
                  { 
                    name: `HR Related: ${HRRelated}%`, 
                    value: HRRelated
                  },
                  { 
                    name: `Relationships: ${relationships}%`, 
                    value: relationships
                  },
                  { 
                    name: `Substance Misuse: ${substance}%`, 
                    value: substance
                  },
                  { 
                    name: `Suicidal Ideation: ${suicide}%`, 
                    value: suicide
                  },
                  { 
                    name: `Trauma: ${trauma}%`, 
                    value: trauma
                  },
                  { 
                    name: `Other: ${other}%`, 
                    value: other
                  }
                ]}
              />
              </IonCol>
            </IonRow>
            <p></p>
            <IonRow>
              <IonCol>
                <IonText className="pie-title">All Clients</IonText>
                <PieCharter
                data = {[
                  {
                    name: `Abuse: ${abuseAll}%`,
                    value: abuseAll
                  },
                  { 
                    name: `Anger: ${angerAll}%`, 
                    value: angerAll
                  },
                  { 
                    name: `Anxiety: ${anxietyAll}%`, 
                    value: anxietyAll
                  },
                  { 
                    name: `Coaching: ${coachingAll}%`, 
                    value: coachingAll
                  },
                  { 
                    name: `Depression: ${depressionAll}%`, 
                    value: depressionAll
                  },
                  {
                    name: `Disorder: ${disorderAll}%`, 
                    value: disorderAll 
                  },
                  {
                    name: `Follow Up: ${followUpAll}%`,
                    value: followUpAll
                  },
                  { 
                    name: `Grief Or Loss: ${griefLossAll}%`, 
                    value: griefLossAll
                  },
                  {
                    name: `Self-Harm: ${selfHarmAll}%`,
                    value: selfHarmAll
                  },
                  { 
                    name:  `Stress: ${stressAll}%`, 
                    value: stressAll
                  },
                  { 
                    name: `HR Related: ${HRRelatedAll}%`, 
                    value: HRRelatedAll
                  },
                  {
                    name: `Insomnia: ${insomniaAll}%`,
                    value: insomniaAll
                  },
                  {
                    name: `Pornography: ${pornographyAll}%`,
                    value: pornographyAll
                  },
                  { 
                    name: `Relationships: ${relationshipsAll}%`, 
                    value: relationshipsAll
                  },
                  { 
                    name: `Substance Misuse: ${substanceAll}%`, 
                    value: substanceAll
                  },
                  { 
                    name: `Suicidal Ideation: ${suicideAll}%`, 
                    value: suicideAll
                  },
                  { 
                    name: `Trauma: ${traumaAll}%`, 
                    value: traumaAll
                  },
                  { 
                    name: `Other: ${otherAll}%`, 
                    value: otherAll
                  }
                ]}
              />
              </IonCol>
            </IonRow>
          </IonCol>
          
          <IonCol className="pie-size middle-col">
          <IonText className="pie-head">Who Reached In</IonText>
          <IonRow className="cusion-row">
              <IonCol>
              <IonText  className="pie-title">Your Company</IonText>
              <PieCharter
                data = {[
                  {
                    name: `Employees: ${employees}%`,
                    value: employees
                  },
                  {
                    name: `Loved Ones: ${lovedOnes}%`,
                    value: lovedOnes
                  },
                  {
                    name: `Others: ${others}%`,
                    value: others
                  },
                ]}
              />
              </IonCol>
            </IonRow>
            <p></p>
            <IonRow>
              <IonCol>
                <IonText className="pie-title">All Clients</IonText>
                  <PieCharter
                    data = {[
                      {
                        name: `Employees: ${employeesAll}%`,
                        value: employeesAll
                      },
                      {
                        name: `Loved Ones: ${lovedOnesAll}%`,
                        value: lovedOnesAll
                      },
                      {
                        name: `Others: ${othersAll}%`,
                        value: othersAll
                      },
                    ]}
                  />
              </IonCol>
            </IonRow>
          </IonCol>
         
        </IonRow>
    </IonGrid> }
    <IonButton color="medium" expand="block"
        onClick={() => auth.signOut()}>
          Logout</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
