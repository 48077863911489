import {
  IonRouterOutlet,
  IonTabBar,
  IonTabs,
  IonTabButton,
  IonLabel,
  IonIcon,
} from '@ionic/react';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import SettingsPage from './pages/SettingsPage';
import EntryPage from './pages/EntryPage';
import { home as homeIcon, settings as settingsIcon, print as printIcon } from 'ionicons/icons';
import { useAuth } from './auth';

const AppTabs: React.FC = () => {
  const { loggedIn } = useAuth();
  if (!loggedIn) {
    return <Redirect to ="/login" />;
  }
  return (
        
        <IonRouterOutlet>
        <Route exact path="/">
          <HomePage/>
        </Route>
        
        </IonRouterOutlet>
        
  );
};

export default AppTabs;
