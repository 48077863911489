import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonList,
  IonLabel,
  IonItem,
  IonImg,
  IonText,
  IonInput,
  IonToolbar,
  IonButton,
} from '@ionic/react';
import React, { useCallback, useState } from 'react';
import { auth } from '../firebase';
import { useHistory } from 'react-router';
import './LoginPage.css';
import '../theme/variables.css';


const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();

  function switchTo() {
    history.replace("/");
  }
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState('');

  const handleReset = useCallback(() => {
    setAlert('')
    auth.sendPasswordResetEmail(email)
        .then(() => setAlert('Check Your Email'))
        .catch((err) => {
          if(err.message === 'There is no user record corresponding to this identifier. The user may have been deleted.') {
            setAlert(`The email "${email}" is not in our records. Please try again.`)
          }
          else{
            setAlert(err.message)
          }});
  }, [email]);
  


  const SearchF= (value:any) =>{
    if(value === "Enter"){
    handleReset();
    }
  }


  return (
    <IonPage className="ion-no-padding">
      <IonHeader>
        <IonToolbar>
          <IonImg src="https://firebasestorage.googleapis.com/v0/b/reportingblunovus.appspot.com/o/logos%2FBluNovus-FullColor-RGB-sq.png?alt=media&token=39d2d84c-c05f-465a-a781-7266687f734d"/>
          <IonTitle color="primary" className="ion-text-centered">Blunovus Reporting</IonTitle>
          
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding">
        <IonList>
          <IonItem>
            <IonLabel position="stacked">User Email For Password Reset</IonLabel>
            <IonInput type="email" value={email} onIonChange={(event) => setEmail(event.detail.value)} onKeyPress={e=> SearchF(e.key)}/>
          </IonItem>
          </IonList>
          <div className="error">
        {
       
        <IonText color="danger">{alert}</IonText>
        }</div>
          
        <IonButton expand="block" type="submit" onClick={handleReset}>Send Reset Email</IonButton>
        <IonButton expand="block" fill="clear" type="submit" onClick={switchTo}>Go Back To Login</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPasswordPage;
