import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonList,
  IonLabel,
  IonItem,
  IonImg,
  IonText,
  IonInput,
  IonToolbar,
  IonButton,
  IonIcon,
  IonLoading,
} from '@ionic/react';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../auth';
import { auth } from '../firebase';
import { useHistory } from 'react-router';
import { eye, eyeOff } from 'ionicons/icons';
import './LoginPage.css';
import '../theme/variables.css';


const LoginPage: React.FC = () => {
  const history = useHistory();
  const { loggedIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState({loading: false, error: false});
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  }
  const handleLogin = async () => {
    try{
    setStatus({loading: true, error: false});
    const credential = await auth.signInWithEmailAndPassword(email, password);
    console.log('credential:', credential);
    console.log(credential.user.uid)
    }
    catch (error) {
      setStatus({loading: false, error: true});
      console.log('error: ', error);
    }
  };
  if(loggedIn) {
    return <Redirect to="/" />;
  }
  const SearchF= (value:any) =>{
    if(value === "Enter"){
    handleLogin();
    }
  }

function switchTo() {
  history.replace("/forgotpassword");
}

  return (
    <IonPage className="ion-no-padding">
      <IonHeader>
        <IonToolbar>
          <IonImg src="https://firebasestorage.googleapis.com/v0/b/blunovus-first-responders.appspot.com/o/logos%2Fblunovusfr.png?alt=media&token=792ce144-ffb9-4e6e-849c-c63632b102d2"/>
          <IonTitle color="primary" className="ion-text-centered">Blunovus First Responders Reporting</IonTitle>
          
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding">
        <IonList>
          <IonItem>
            <IonLabel position="stacked">Email</IonLabel>
            <IonInput type="email" value={email} onIonChange={(event) => setEmail(event.detail.value)} onKeyPress={e=> SearchF(e.key)}/>
            
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">Password</IonLabel>
            <IonInput type={showPassword ? "text" : "password"} value={password} onIonChange={(event) => setPassword(event.detail.value)} onKeyPress={e=> SearchF(e.key)}/>
            <IonIcon id="icons" onClick={togglePassword} icon={showPassword ? `${eyeOff}` : `${eye}`} className="icon-toggle"/>
            
          </IonItem>
        </IonList><div className="error">
        {
        status.error &&
        <IonText color="danger">Invalid Username or Password</IonText>
        }</div>
        <IonButton expand="block" type="submit" onClick={handleLogin}>Login</IonButton>
        <IonLoading isOpen={status.loading}/>
        <IonButton expand="block" fill="clear" onClick={switchTo}>Forgot Password?</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
